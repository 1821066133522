import React from 'react';
import './NavLink.css';
import PropTypes from 'prop-types';
import {
	Link, Icon
} from '@chakra-ui/react';

import {usePageStore} from '../store/store';

const NavLink = ({
	to,
	icon,
	color,
	children,
	...rest
}) => {
	const currentPage = usePageStore((state) => state.currentPage);
	const changePage = usePageStore((state) => state.changePage);


	const handleClick = (event) => {
		if (event.metaKey || event.ctrlKey) {
			return;
		}

		event.preventDefault();
		changePage(to);
	};

	const colorChoice = {
		blue: {
			color: 'blue.700',
			fontWeight: '600'
		}
	};

	return (
		<Link {...rest} className='nav-link' href={to} onClick={handleClick} fontSize="15px" {...colorChoice[color || 'blue']}>
			<Icon boxSize={5} as={icon}/>
			{children}
		</Link>
	);
};

NavLink.propTypes = {
	to: PropTypes.string.isRequired,
	icon: PropTypes.any,
	color: PropTypes.string,
	children: PropTypes.node.isRequired
};


export default NavLink;
