import '../App.css';
import './SimulatorPage.css';
import React from 'react';
import {
	Flex, Button, Heading, Divider
} from '@chakra-ui/react';

import PropTypes from 'prop-types';
import Stepper from '../components/Stepper';
import SliderSection from './SliderSection';
import ModelSection from './ModelSection';
import ConfigSection from './ConfigSection';
import {useConfigurationStore} from '../store/store';
import {CURRENT} from '../store/constants';

const DistConfigPage = ({isLoading, postSimulation}) => {
	const configName = useConfigurationStore((state) => state.name);
	const disableSteppers = configName == CURRENT;

	return (
		<div>
			<Flex flexDir="column" textAlign="left" m="2rem 4rem" className='page-title'>
				<Heading size='xs' color='orange.500'>SIMULATOR</Heading>
				<Heading>Configuration</Heading>
			</Flex>
			<Heading m="2rem 4rem" size="md">Select Model Variant:</Heading>
			<Flex m="2rem 4rem" justifyContent="flex-start">
				<ModelSection/>
			</Flex>
			<Divider size="xl" />
			<Heading m="2rem 4rem" size="md">Choose your Configuration:</Heading>
			<Flex m="2rem 4rem" justifyContent="space-evenly">
				<ConfigSection/>
			</Flex>
			<Flex p="2rem 4rem" borderTop="1px solid #DDDEDE" className='page-actions' flexDir="row" justifyContent="start" >
				<Flex direction="column">
					<Heading size='md' mb="1rem">Customize Distribution</Heading>
					<Flex direction="row">

						<Stepper
							title='Tiers'
							value={'tiers'}
							maxValue={5}
							minValue={3}
							mr="2rem"
							disable={disableSteppers}
						/>

						<Stepper
							title='Lead Rank'
							value={'ranks'}
							maxValue={5}
							minValue={3}
							disable={disableSteppers}
						/>
					</Flex>

				</Flex>
			</Flex>
			<Flex wrap="wrap">
				<SliderSection/>
			</Flex>
			<Flex p="2rem 4rem" borderTop="1px solid #DDDEDE" flexDir="row" justifyContent="flex-end" >
				{/* <NavLink to='models' colorScheme="blue" icon={BsArrowLeftShort}>Back to Models</NavLink> */}
				<Button isLoading={isLoading} colorScheme='blue' onClick={() => postSimulation()}>Simulate</Button>
			</Flex>

		</div>
	);
};

DistConfigPage.propTypes = {
	isLoading: PropTypes.bool,
	postSimulation: PropTypes.func
};

export default DistConfigPage;
