import React, {createContext, useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {CognitoUser, AuthenticationDetails, CognitoRefreshToken} from 'amazon-cognito-identity-js';

import Pool from '../UserPool';
import {useNavigate} from 'react-router-dom';
import {useToast} from '@chakra-ui/react';
import {useConfigurationStore, useSessionStore} from '../store/store';
const AccountContext = createContext();

const Account = ({children}) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
	const [exp, setExp] = useState(JSON.parse(localStorage.getItem('exp')));
	const toast = useToast();
	const navigate = useNavigate();
	const [cogUserGlobal, setCogUserGlobal] = useState(null);
	const updateExp = useSessionStore((state) => state.setExp);
	const setSuperUser = useSessionStore((state) => state.setSuperUser);
	const resetState = useConfigurationStore((state) => state.reset);

	const validateUserRole = (session) => {
		const groups = session.getIdToken().payload['cognito:groups'];
		if (groups?.includes('admins')) {
			setSuperUser(true);
		} else {
			setSuperUser(false);
		}
	};
	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			if (user) {
				user.getSession(async (err, session) => {
					if (err) {
						reject(err);
					} else {
						const userAttributes = await new Promise((resolve, reject) => {
							user.getUserAttributes((err, attributes) => {
								if (err) {
									reject(err);
								} else {
									const results = {};

									for (const attribute of attributes) {
										const {Name, Value} = attribute;
										results[Name] = Value;
									}

									resolve(results);
								}
							});
						});
						const idToken = session.getIdToken();
						const jwt = idToken.getJwtToken();
						validateUserRole(session);
						resolve({
							user,
							headers: {
								Authorization: jwt
							},
							...session,
							...userAttributes
						});
					}
				});
			} else {
				reject(Error('No user session found'));
			}
		});
	};

	const getSessionStable = useCallback(
		getSession,
		[]
	);

	const logout = (manual = false) => {
		resetState();
		localStorage.removeItem('exp');
		localStorage.removeItem('refreshToken');
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
			if (!manual) {
				toast({
					title: 'Session Timeout',
					description: 'Your session is timed out, please log in again.',
					status: 'warning',
					duration: null,
					isClosable: true
				});
				navigate('/login');
			} else {
				toast({
					title: 'Sucessfully Logged out',
					status: 'success',
					duration: 9000,
					isClosable: true
				});
				navigate('/login');
			}
		}
	};

	const refreshSession = useCallback(() => {
		const user = Pool.getCurrentUser();
		const token = new CognitoRefreshToken({RefreshToken: refreshToken});
		user.refreshSession(token, (err, session) => {
			setRefreshToken(session.refreshToken.token);
			localStorage.setItem('refreshToken', session.refreshToken.token);
			const expSeconds = session.accessToken.payload.exp;
			setExp(expSeconds);
			localStorage.setItem('exp', JSON.stringify(expSeconds));
			console.log(expSeconds);
		});
	}, [refreshToken]);

	useEffect(() => {
		if (exp) {
			updateExp(exp);
		}
	}, [exp]);

	useEffect(() => {
		getSessionStable()
			.then((session) => {
				console.log('Session:', session);
				setIsAuthenticated(true);
				setIsAuthenticating(false);
			})
			.catch((error) => {
				console.error('Not Logged in!');
				setIsAuthenticating(false);
			});
	}, [getSessionStable]);

	const authenticate = async (Username, Password) => {
		return await new Promise((resolve, reject) => {
			const cogUser = new CognitoUser({Username, Pool});
			const authDetails = new AuthenticationDetails({
				Username,
				Password
			});

			cogUser.authenticateUser(authDetails, {
				onSuccess: (data) => {
					console.log('onSuccess: ', data);
					validateUserRole(data);
					setIsAuthenticated(true);
					setRefreshToken(data.refreshToken.token);
					localStorage.setItem('refreshToken', data.refreshToken.token);
					const expSeconds = data.accessToken.payload.exp;
					setExp(expSeconds);
					localStorage.setItem('exp', JSON.stringify(expSeconds));
					resolve({status: 'logged_in', payload: data});
				},
				onFailure: (data) => {
					console.log('onFailure: ', data);
					reject(data);
				},
				mfaSetup: function(challengeName, challengeParameters) {
					cognitoUser.associateSoftwareToken(this);
				},

				associateSecretCode: function(secretCode) {
					const challengeAnswer = prompt('Please input the TOTP code.', '');
					cognitoUser.verifySoftwareToken(challengeAnswer, 'My TOTP device', this);
				},

				selectMFAType: function(challengeName, challengeParameters) {
					const mfaType = prompt('Please select the MFA method.', ''); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
					cognitoUser.sendMFASelectionAnswer(mfaType, this);
				},

				totpRequired: function(secretCode) {
					const challengeAnswer = prompt('Please input the TOTP code.', '');
					cognitoUser.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
				},

				mfaRequired: function(codeDeliveryDetails) {
					// MFA is required to complete user authentication.
					// Get the code from user and call
					console.log(codeDeliveryDetails);
				},
				newPasswordRequired: (userAttributes, requiredAttributes) => {
					setCogUserGlobal(cogUser);
					console.log('newPasswordRequired: ', userAttributes);
					console.log('Required attributes: ', requiredAttributes);
					resolve({status: 'change_password', payload: userAttributes});
				}
			});
		});
	};

	const completeChallenge = useCallback(async (Username, newPassword, userAttr) => {
		return await new Promise((resolve, reject) => {
			cogUserGlobal.completeNewPasswordChallenge(newPassword, userAttr, {
				onSuccess: (result) => {
					console.log('NEW PASSWORD COMPLETED', result);
					validateUserRole(result);
					setIsAuthenticated(true);
					resolve(result);
				},
				mfaRequired: function(codeDeliveryDetails) {
					// MFA is required to complete user authentication.
					// Get the code from user and call
					console.log(codeDeliveryDetails);
				},
				onFailure: (err) => {
					if (err.name === 'NotAuthorizedException') {
						toast({
							title: 'Session Timeout',
							description: 'Your session is timed out, please log in again.',
							status: 'warning',
							duration: 9000,
							isClosable: true
						});
						navigate('/login');
					} else {
						reject(err);
					}
				}
			});
		});
	}, [cogUserGlobal]);


	return <AccountContext.Provider value={{authenticate, isAuthenticated, isAuthenticating, logout, getSessionStable, completeChallenge, refreshSession}}>{children}</AccountContext.Provider>;
};


Account.propTypes = {
	children: PropTypes.node.isRequired
};

export {Account, AccountContext};
