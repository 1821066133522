
import PropTypes from 'prop-types';
import {usePageStore} from '../store/store';

function SimulatorRoute({path, children}) {
	const currentPage = usePageStore((state) => state.currentPage);

	if (path === currentPage) {
		return children;
	}

	return null;
}
SimulatorRoute.propTypes = {
	path: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default SimulatorRoute;
