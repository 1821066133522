

import React, {useState, useContext} from 'react';
import {
	Button, FormControl, FormLabel, FormHelperText, FormErrorMessage, Input, Heading, useToast
} from '@chakra-ui/react';

import {useNavigate} from 'react-router-dom';
import {AccountContext} from '../context/account';

function Login2Page() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {authenticate} = useContext(AccountContext);
	const loginNotification = useToast();

	const onSubmit = (event) => {
		setLoading(true);
		event.preventDefault();
		console.log('submit');
		authenticate(email, password)
			.then((data) => {
				if (data.status === 'change_password') {
					navigate('/change_password', {replace: true, state: data.payload});
				} else {
					navigate('/', {replace: true});
				}
				setLoading(false);
			})
			.catch((err) => {
				console.error('Failure', err);
				loginNotification({
					title: 'Login Error',
					description: err.message,
					status: 'error',
					duration: 9000,
					isClosable: true
				});
				setLoading(false);
			});
	};

	const labelStyles = {
		fontWeight: '700',
		fontSize: 'sm'
	};

	return (
		<div >
			<form onSubmit={onSubmit} autoComplete="off" className='login-form'>
				<Heading size="md" mb="2rem">Log in</Heading>
				<FormControl mb="1.5rem">
					<FormLabel {...labelStyles}>Email</FormLabel>
					<Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
				</FormControl>
				<FormControl mb="1.5rem">
					<FormLabel {...labelStyles}>Password</FormLabel>
					<Input colorScheme="orange" type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
				</FormControl>
				<Button isLoading={isLoading} type="submit" colorScheme="blue" w="100%">Log in</Button>
			</form>
		</div >
	);
}

export default Login2Page;
