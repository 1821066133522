// 1) Import the React and ReactDOM libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// import `ChakraProvider` component
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';

import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Account} from './context/account';
// 2) Get a reference to the div with ID root
const el = document.getElementById('root');

// 3) Tell react to take control of that element
const root = ReactDOM.createRoot(el);

const customTheme = extendTheme({
	fonts: {
		body: `'Montserrat', sans-serif`,
		heading: `'Montserrat', sans-serif`
	},
	colors: {
		orange: {
			500: '#F16936'
		},
		gray: {
			700: '#434346',
			600: '#6E6F72'
		},
		lime: {
			600: '#52A206'
		}
	},
	styles: {
		global: {
			// styles for the `body`
			body: {
				color: 'gray.700'
			},
			// styles for the `a`
			a: {
			}
		}
	}
});
const queryClient = new QueryClient();

// 4) Show the component on the screen
root.render(
	<React.StrictMode>
		<ChakraProvider theme={customTheme} >
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<Account>
						<App />
					</Account>
					<ReactQueryDevtools />
				</QueryClientProvider>
			</BrowserRouter>
		</ChakraProvider>
	</React.StrictMode>
);
