import React from 'react';
import {useConfigurationStore} from '../store/store';
import ConfigCard from '../components/ConfigCard';
import {changeModel} from '../store/helpers';
import {AiOutlineCluster} from 'react-icons/ai';

const ModelSection = () => {
    const models = useConfigurationStore((state) => state.models);
    const currModel = useConfigurationStore((state) => state.model);
    const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);
    const renderedModels = models.map((model) => {
        return (
            <ConfigCard
                key={model}
                onClick={() => changeModel(models, model, updateConfigStore)}
                active={model === currModel}
                title={model}
                icon={AiOutlineCluster}
                subtitle='Model Variant' />
        );
    });
    return (
        renderedModels
    );
};

export default ModelSection;
