export const CHANGE_TIERS = 'add_one_to_tiers';
export const CHANGE_RANKS = 'add_one_to_bins';
export const CHANGE_MODEL = 'change_model_name';
export const ADD_PRE_CONFIGS = 'add_opt';
export const CHANGE_CONFIG = 'change_configuration';
export const SET_CUSTOM_CONFIG = 'set_custom';
export const OPTIMIZED = 'optimized';
export const UNIFORM = 'uniform';
export const CURRENT = 'current';
export const CUSTOMIZED = 'custom';
export const RANDOM = 'random';
export const CHANGE_ACCOUNT = 'change_account';

