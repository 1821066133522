import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useLocation, Navigate} from 'react-router-dom';
import {AccountContext} from '../context/account';


function RequireAuth({children}) {
	const location = useLocation();
	const {isAuthenticated} = useContext(AccountContext);

	if (!isAuthenticated) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{from: location}} replace />;
	}

	return children;
}

RequireAuth.propTypes = {
	children: PropTypes.node.isRequired
};

export default RequireAuth;
