
import '../App.css';
import React, {useContext} from 'react';


import {Container, Link, Box, Tag, TagLabel, TagLeftIcon, Portal, Flex, Image, Menu, MenuButton, Button, MenuOptionGroup, MenuList, MenuItemOption} from '@chakra-ui/react';
import {Outlet, useNavigate} from 'react-router-dom';
import {AccountContext} from '../context/account';
import {GoChevronDown} from 'react-icons/go';
import {FaUserCircle} from 'react-icons/fa';
import {useSessionStore, useConfigurationStore} from '../store/store';
import {CHANGE_ACCOUNT} from '../store/constants';
function MainLayout() {
	const {logout} = useContext(AccountContext);
	const isSuperUser = useSessionStore((state) => state.isSuperUser);
	const accounts = useConfigurationStore((state) => state.accounts);
	const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);
	const curAccount = useConfigurationStore((state) => state.curAccount);
	const handleLogout = () => {
		logout(true);
	};

	const renderMenuItems = accounts.map((a) => {
		return (
			<MenuItemOption value={a} key={a}>{a}</MenuItemOption>
		);
	});

	const handleAccountChange = (value) => {
		console.log(value);
		updateConfigStore({
			type: CHANGE_ACCOUNT,
			payload: value
		});
	};

	return (

		<div className='wrapper'>
			<Box className='header' alignItems="center" justifyContent="space-between">
				<Container maxW="1200px" display="flex" alignItems="center" justifyContent="space-between">
					<Image htmlWidth="350px" src="https://www.propair.ai/wp-content/uploads/2021/03/logo.png" />
					<Flex>
						{isSuperUser &&
							<Menu>
								<MenuButton as={Button} rightIcon={<GoChevronDown />}>
									Accounts
								</MenuButton>
								<Portal>
									<MenuList minWidth='240px'>
										<MenuOptionGroup value={curAccount} title='Choose Account' type='radio' onChange={handleAccountChange}>
											{renderMenuItems}
										</MenuOptionGroup>
									</MenuList>
								</Portal>
							</Menu>
						}
						{curAccount &&
							<Tag size='lg' ml="2rem" variant='solid' colorScheme='blue'>
								<TagLeftIcon as={FaUserCircle} />
								<TagLabel>{curAccount}</TagLabel>
							</Tag>
						}
						<Link ml="2rem" onClick={handleLogout} color="blue.500" fontWeight="700">Sign Out</Link>
					</Flex>
				</Container>
			</Box>
			<Container mt="150px" mb="100px" maxW='1200px' p="1rem 0px" className='main-content'>
				<Outlet />
			</Container>
		</div>

	);
}

export default MainLayout;
