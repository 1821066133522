import React, {useEffect} from 'react';
import {useConfigurationStore, useCustomSliderGroupStore} from '../store/store';
import ConfigCard from '../components/ConfigCard';
import {MdOutlineAnalytics} from 'react-icons/md';
import {IoDiceSharp} from 'react-icons/io5';
import {RiScales3Line} from 'react-icons/ri';
import {HiWrenchScrewdriver} from 'react-icons/hi2';
import {setCustomConfig, updateConfig} from '../store/helpers';
import {TbCurrentLocation} from 'react-icons/tb';

const ConfigSection = () => {
    const configName = useConfigurationStore((state) => state.name);
    const model = useConfigurationStore((state) => state.model);
    const account = useConfigurationStore((state) => state.curAccount);
    const getCurrentCache = useConfigurationStore((state) => state.getCurrentCache);
    const disableCustom = useCustomSliderGroupStore((state) => state.disabled);
    const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);

    const getConfigName = useConfigurationStore((state) => state.getConfigName);
    const setValue = useConfigurationStore((state) => state.setValue);
    const setCustomValue = useCustomSliderGroupStore((state) => state.setCustomValue);


    const cache = getCurrentCache();
    const curConfig = cache?.[account]?.[model];
    const disableCur = !curConfig;

    const handleConfigChange = (option, updateFn) => {
        if (option === 'custom') {
            setValue();
        } else {
            const conf = getConfigName();
            if (conf === 'custom') {
                setCustomValue();
            }
            updateConfig(option, updateFn);
        }
    };

    return (
        <>
            <ConfigCard
                onClick={() => handleConfigChange('optimized', updateConfigStore)}
                active={configName === 'optimized'}
                title='Optimized'
                subtitle='ProPair Lead Distribution' icon={MdOutlineAnalytics}/>
            <ConfigCard
                onClick={() => handleConfigChange('current', updateConfigStore)}
                active={configName === 'current'}
                title='Current'
                disabled={disableCur}
                subtitle='Your Current Configuration' icon={TbCurrentLocation}/>
            <ConfigCard
                onClick={() => handleConfigChange('random', updateConfigStore)}
                active={configName === 'random'}
                title='Random'
                subtitle='No Rhyme or Reason to Lead Distribution' icon={IoDiceSharp}/>
            <ConfigCard
                onClick={() => handleConfigChange('custom', updateConfigStore)}
                active={configName === 'custom'}
                title='Custom'
                disabled={disableCustom}
                subtitle='Customized Distribution' icon={HiWrenchScrewdriver}/>
        </>
    );
};

export default ConfigSection;
