import React from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Button
} from '@chakra-ui/react';
import './timer-styles.css';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import PropTypes from 'prop-types';

function SessionRefreshModal({isOpen, refreshSession, logOut, remainingSeconds, onSessionModalClose, setSessionAlertOpen}) {
    const cancelRef = React.useRef();

    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            setSessionAlertOpen(false);
            logOut();
        } else {
            return (
                <div className='timer'>
                    <div className='value'>{remainingTime}</div>
                </div>
            );
        }
    };

    const onYes = () => {
        refreshSession();
        setSessionAlertOpen(false);
    };

    return (

        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Session Expiring
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <div className='timer-wrapper'>
                            <CountdownCircleTimer
                                size={100}
                                isPlaying
                                duration={remainingSeconds}
                                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[60, 40, 20, 0]}
                            >
                                {renderTime}
                            </CountdownCircleTimer>
                        </div>
                        Your session is about to expire and you will be automatically logged out of the system. Do you
                        wish to continue ?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => logOut(true)}>
                            No
                        </Button>
                        <Button colorScheme='green' onClick={onYes} ml={3}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

SessionRefreshModal.propTypes = {
    logOut: PropTypes.func,
    refreshSession: PropTypes.func,
    onSessionModalClose: PropTypes.func,
    remainingSeconds: PropTypes.number,
    isOpen: PropTypes.bool,
    setSessionAlertOpen: PropTypes.func
};

export default SessionRefreshModal;
