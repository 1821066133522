

import React, {useState, useEffect, useContext} from 'react';
import {
	Button, FormControl, FormLabel, Text, FormErrorMessage, Input, Heading, Icon, InputGroup, InputLeftAddon, useToast
} from '@chakra-ui/react';
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';

import UserPool from '../UserPool';
import {useLocation, useNavigate} from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';
import {HiCheckCircle} from 'react-icons/hi2';

import {RxDotFilled} from 'react-icons/rx';
import {AccountContext} from '../context/account';

function ChangePasswordPage() {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [phone, setPhone] = useState('');
	const location = useLocation();
	const {completeChallenge} = useContext(AccountContext);

	const loginNotification = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		console.log(location);
		if (location.state) {
			setEmail(location.state.email);
		}
	}, [location]);

	const onSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		completeChallenge(email, password, {phone_number: `+1${phone}`})
			.then((data) => {
				console.log('Success:', data);
				setLoading(false);
				navigate('/');
			}).catch((error) => {
				console.error('Error:', error);
				loginNotification({
					title: 'Setup error',
					description: error.message,
					status: 'error',
					duration: 9000,
					isClosable: true
				});
				setLoading(false);
			});
	};

	const labelStyles = {
		fontWeight: '700',
		fontSize: 'sm'
	};

	const isPasswordConfirmed = confirmPassword === password;

	return (<div>
		<form onSubmit={onSubmit} autoComplete="off" className='login-form'>
			<Heading size="md" mb="2rem">Set up new user</Heading>
			<FormControl mb="1.5rem">
				<FormLabel {...labelStyles}>New password</FormLabel>
				<Input type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
			</FormControl>
			<FormControl mb="1.5rem" isInvalid={!isPasswordConfirmed}>
				<FormLabel {...labelStyles}>Confirm password</FormLabel>
				<Input type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
			</FormControl>

			<Text color="gray.600">Your password should contain:</Text>
			<PasswordChecklist
				className='pass-checklist'
				rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
				minLength={8}
				value={password}
				valueAgain={confirmPassword}
				messages={{
					minLength: 'At least 8 characters in length',
					specialChar: 'At least one special character',
					number: 'At least one number',
					capital: 'At least one uppercase letter'
				}}
				onChange={(isValid) => {
					console.log(isValid);
				}} iconComponents={{ValidIcon: <Icon boxSize={5} as={HiCheckCircle} color="lime.600" />, InvalidIcon: <Icon boxSize={5} as={RxDotFilled} color="gray.500" />}}
			/>
			<FormControl mt="1.5rem" mb="1.5rem" isRequired>
				<FormLabel {...labelStyles}>Phone Number</FormLabel>
				<InputGroup>
					<InputLeftAddon children='+1' />{/* eslint-disable-line  */}
					<Input type='tel' maxLength={10} placeholder='phone number' value={phone}
						onChange={(event) => setPhone(event.target.value)} />
				</InputGroup>
			</FormControl>
			<Button isLoading={isLoading} mt="1.5rem" type="submit" colorScheme="blue" w="100%">Set up user</Button>
		</form>
	</div >
	);
}

export default ChangePasswordPage;
