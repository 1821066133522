import React from 'react';
import {Flex, Box, Button, Center, Icon, Card, Stack, CardBody, Text, CardFooter, Heading} from '@chakra-ui/react';
import {MdSettings} from 'react-icons/md';
import PropTypes from 'prop-types';

const ConfigCard = ({title, active, subtitle, icon, disabled, ...rest}) => {
	return (
		<div>
			<button disabled={disabled} className={`config-card ${disabled ? 'disabled' : ''}`} {...rest}>
				{active && <div className='active-card' />}
				{icon && <Icon boxSize='7' as={icon} />}
				<div className="card-body">
					<Heading size='md'>{title}</Heading>
					<Text>{subtitle}</Text>
				</div>
			</button>
		</div>
	);
};

ConfigCard.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	icon: PropTypes.any,
	active: PropTypes.bool,
	disabled: PropTypes.bool
};

export default ConfigCard;
