import './Stepper.css';
import React, {useCallback, useEffect, useState} from 'react';
import {
	IconButton, Box
} from '@chakra-ui/react';

import {FaPlus, FaMinus} from 'react-icons/fa';
import PropTypes from 'prop-types';
import {useConfigurationStore, useCustomSliderGroupStore} from '../store/store';
import {addToDistribution, subtractFromDistribution, updateConfig} from '../store/helpers';
import {CUSTOMIZED, OPTIMIZED} from '../store/constants';

const Stepper = ({
	title,
	value,
	minValue,
	maxValue,
	disable,
	...rest
}) => {
	const val = useConfigurationStore((state) => state[value]);
	const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);
	const configName = useConfigurationStore((state) => state.name);
	const setCustomValue = useCustomSliderGroupStore((state) => state.setCustomValue);
	const [func, setFunc] = useState(null);

	const subtractFunction = () => {
		if (configName === CUSTOMIZED) {
			setCustomValue();
			updateConfig(OPTIMIZED, updateConfigStore);
			setFunc(() => subtractFromDistribution);
		} else {
			subtractFromDistribution(val, value, updateConfigStore);
		}
	};

	const addFunction = () => {
		if (configName === CUSTOMIZED) {
			setCustomValue();
			updateConfig(OPTIMIZED, updateConfigStore);
			setFunc(() => addToDistribution);
		} else {
			addToDistribution(val, value, updateConfigStore);
		}
	};

	useEffect(() => {
		if (func) {
			func(val, value, updateConfigStore);
			setFunc(null);
		}
	}, [func]);

	return (
		<Box {...rest}>
			<p className='stepper-title'>{title}</p>
			<div className='stepper-actions'>
				<IconButton className='stepper-left' colorScheme="blackAlpha" disabled={disable || (minValue && val === minValue)} onClick={subtractFunction} aria-label='Subtract' icon={<FaMinus />} />
				<span className='stepper-value'>{val}</span>
				<IconButton className='stepper-right' colorScheme="blackAlpha" disabled={disable || (maxValue && val === maxValue)} onClick={addFunction} aria-label='Add' icon={<FaPlus />} />
			</div>
		</Box>
	);
};

Stepper.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	disable: PropTypes.bool
};


export default Stepper;
