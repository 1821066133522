import React, {useEffect} from 'react';
import {Box} from '@chakra-ui/react';
import SilderGroup from '../components/SliderGroup';
import {useConfigurationStore} from '../store/store';

const SliderSection = () => {
    const configValue = useConfigurationStore((state) => state.value);
    const renderedSliderGroups = configValue.map((item, index) => {
        const tierValues = item;
        const newAr = tierValues.map((x) => x * 100);
        return (
            <Box className='slider-group-column' key={index}>
                <SilderGroup className='slider-group' percentages={newAr} tier={index} rank={index} />
            </Box>
        );
    });
    return (
        renderedSliderGroups
    );
};

export default SliderSection;
