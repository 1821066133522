import React, {useCallback, useContext, useEffect, useState} from 'react';
import './SimulatorPage.css';

import {Center, Spinner, useToast} from '@chakra-ui/react';

import '../App.css';
import ChartPage from './ChartPage';
import DistConfigPage from './DistConfigPage.js';
import SimulatorRoute from '../components/SimulatorRoute';
import {useMutation, useQuery} from '@tanstack/react-query';
import {fetchOptimizations, simulateRequest} from '../api';
import {useConfigurationStore, usePageStore, useSessionStore} from '../store/store';
import {ADD_PRE_CONFIGS} from '../store/constants';
import {AccountContext} from '../context/account';
import SessionRefreshModal from '../modals/SessionRefreshModal';
import _ from 'lodash';

const SimulatorPage = () => {
    const changePage = usePageStore((state) => state.changePage);
    const simulatingToast = useToast();
    const {logout, refreshSession} = useContext(AccountContext);
    const [chartData, setChartData] = useState({});
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    const [sessionAlertOpen, setSessionAlertOpen] = useState(false);
    const getModel = useConfigurationStore((state) => state.getModel);
    const getValue = useConfigurationStore((state) => state.getValue);
    const curAccount = useConfigurationStore((state) => state.curAccount);
    const exp = useSessionStore((state) => state.exp);
    const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);
    const {getSessionStable} = useContext(AccountContext);

    const validateSum = (a) => {
		return Math.abs(_.sum(a) - 1) > 0.1;
	};

    const simulate = async () => {
        const {headers, user} = await getSessionStable();


        const params = {
            distro_name: 'custom',
            account: curAccount,
            model: getModel(),
            run_count: 2000,
            runs_per_lambda: 50,
            distro_list: getValue()
        };
        return simulateRequest(params, headers);
    };

    const openSessionDialog = () => {
        setSessionAlertOpen(true);
    };

    const onSessionModalClose = useCallback(() => {
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        const timeout = exp - currentTimeSeconds;
        setTimeout(logout, timeout * 3000);
        setSessionAlertOpen(false);
    }, [exp]);

    useEffect(() => {
        if (exp) {
            const currentTimeSeconds = Math.round(+new Date() / 1000);
            const remaining = exp - currentTimeSeconds;
            const timeout = remaining > 60 ? remaining - 60 : 0;
            if (timeout > 60) {
                setRemainingSeconds(60);
            } else {
                setRemainingSeconds(remaining);
            }
            const sessionTimeout = setTimeout(openSessionDialog, timeout * 1000);

            return () => {
                return clearTimeout(sessionTimeout);
            };
        }
    }, [exp]);

    const {isLoading, mutate} = useMutation({
        mutationFn: simulate,
        onMutate: () => {
            simulatingToast({
                title: 'Simulation in progress',
                description: 'Please wait a few seconds while we simulate your data',
                status: 'info',
                duration: 9000,
                isClosable: true
            });
        },
        onError: (a, b, c) => {
            simulatingToast({
                title: 'Simulation Error!',
                description: a?.message,
                status: 'error',
                duration: 9000,
                isClosable: true
            });
        },
        onSuccess: (data, variables, context) => {
            simulatingToast({
                title: 'Simulation success!',
                status: 'success',
                duration: 9000,
                isClosable: true
            });
            setChartData(data);
            changePage('charts');
        }
    });

    const startSimulation = () => {
        const distValue = getValue();

        for (const arr of distValue) {
            if (validateSum(arr)) {
                simulatingToast({
                    title: 'Invalid Request!',
                    description: 'Please verify that your sliders add up to 100',
                    status: 'error',
                    duration: 9000,
                    isClosable: true
                });
                return;
            }
        }

        mutate();
    };

    const fetchOpt = async (key) => {
        const {headers} = await getSessionStable();
        console.log('FETCHING NEW OPT', headers);

        return fetchOptimizations(headers);
    };

    const optimizationQuery = useQuery({
        queryKey: ['optimizations', exp],
        queryFn: fetchOpt,
        staleTime: Infinity,
        onSuccess: (data) => {
            updateConfigStore({
                type: ADD_PRE_CONFIGS,
                payload: data
            });
        }
    });


    if (optimizationQuery.status === 'loading') {
        return <Center pt={5} pb={5}><Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
            label="Loading..."
        /></Center>;
    }

    return (
        <div>
            <SimulatorRoute path="distribution"><DistConfigPage isLoading={isLoading}
                postSimulation={startSimulation} /></SimulatorRoute>
            {/* <SimulatorRoute path="models"><ModelConfigPage /></SimulatorRoute> */}
            <SimulatorRoute path="charts"><ChartPage chartData={chartData} /></SimulatorRoute>
            <SessionRefreshModal isOpen={sessionAlertOpen} onSessionModalClose={onSessionModalClose}
                setSessionAlertOpen={setSessionAlertOpen}
                logOut={logout} refreshSession={refreshSession} remainingSeconds={remainingSeconds} />
        </div>
    );
};

export default SimulatorPage;
