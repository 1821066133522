import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './SliderGroup.css';
import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	Tooltip,
	Box,
	IconButton,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Flex,
	Text
} from '@chakra-ui/react';
import {useBoolean} from '@chakra-ui/react';

import {setCustomConfig} from '../store/helpers';
import {useConfigurationStore, useCustomSliderGroupStore} from '../store/store';
import {TiLockClosed, TiLockOpen} from 'react-icons/ti';
import _ from 'lodash';

const SilderGroup = ({percentages, tier, rank}) => {
3232;
	// const [values, setValues] = useState(percentages);
	const [showTooltip, setShowTooltip] = React.useState(false);
	const [flag, setFlag] = useBoolean(true);
	const [inputValues, setInputValues] = useState(percentages);
	const updateConfigStore = useConfigurationStore((state) => state.updateConfigStore);
	const setActive = useCustomSliderGroupStore((state) => state.setActive);
	const tierMap = {
		0: 'A',
		1: 'B',
		2: 'C',
		3: 'D',
		4: 'E'
	};

	useEffect(() => {
		setInputValues(percentages);
	}, [percentages]);


	const labelStylesL = {
		minW: '8px',
		ml: '1.5',
		borderBottom: '3px solid',
		borderColor: 'gray.400',
		borderRadius: '4'
	};

	const labelStylesS = {
		minW: '5px',
		ml: '2',
		borderBottom: '4px solid',
		borderColor: 'gray.300',
		borderRadius: '4'
	};

	const validateSum = (a) => {
		return Math.abs(_.sum(a) - 100) > 0.1;
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.target.blur();
		}
	};

	const handleInputChange = (v, i) => {
		const newA = [...inputValues];
		newA[i] = v;
		setInputValues(newA);
	};

	const changeSliders = (rank, i, v) => {
		setActive();
		setCustomConfig(rank, i, v, updateConfigStore, flag);
	};

	const sliders = percentages.map((p, i) => {
		return (
			<Flex direction="column" key={i} mr='0.5rem'>

				<Slider
					focusThumbOnChange={false}
					value={p}
					minH='40'
					size="lg"
					min={0}
					max={100}
					step={5}
					orientation="vertical"
					onChange={(v) => changeSliders(rank, i, v)}
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
				>
					<SliderTrack bg="#E4E8F3">
						<SliderFilledTrack bg="#CED1DC" />
					</SliderTrack>
					<Tooltip
						hasArrow
						bg='#1575D5'
						color='white'
						placement='right'
						isOpen={showTooltip}
						label={`${Math.round(p)}%`}>
						<SliderThumb className='rollout' boxShadow='md' bg="#1575D5" border="2px solid white" />
					</Tooltip>
					<SliderMark value={2} {...labelStylesL}></SliderMark>
					<SliderMark value={12.5} {...labelStylesS}></SliderMark>
					<SliderMark value={25} {...labelStylesS}></SliderMark>
					<SliderMark value={37.5} {...labelStylesS}></SliderMark>
					<SliderMark value={50} {...labelStylesL}></SliderMark>
					<SliderMark value={62.5} {...labelStylesS}></SliderMark>
					<SliderMark value={75} {...labelStylesS}></SliderMark>
					<SliderMark value={87.5} {...labelStylesS}></SliderMark>
					<SliderMark value={98} {...labelStylesL}></SliderMark>
				</Slider>
				<NumberInput
					step={5}
					maxW={20}
					mt='1rem'
					size="sm"
					min={0}
					max={100}
					inputMode="decimal"
					isInvalid={validateSum(inputValues)}
					value={inputValues[i]}
					onChange={(v) => handleInputChange(v, i)}
					onKeyPress={handleKeyPress}
					onBlur={(e) => {
						e.preventDefault();
						changeSliders(rank, i, e.target.value);
					}
					}
					// onChange={(v) => changeSliders(rank, i, v)}
					>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
				<Text fontSize="md" fontWeight="700" textAlign="center" p="2" color="#34679A">{tierMap[i]}</Text>
			</Flex >
		);
	});

	return (
		<Box display="flex" flexDir="column" alignItems="center" className='slider-group'>
			<IconButton cursor='pointer' colorScheme='gray' size='xs' as={flag ? TiLockClosed : TiLockOpen} className='slider-group-lock' onClick={setFlag.toggle}/>
			<Text fontSize="sm" color="#434346" fontWeight='700' mb="1rem">LEAD RANK VALUE {tier + 1}</Text>
			<Box display="flex" flexDir="row">
				{sliders}
			</Box>
			<Text fontSize="sm" fontWeight='700' color="#34679A" textAlign="center">AGENT TIERS</Text>
		</Box>
	);
};

SilderGroup.propTypes = {
	percentages: PropTypes.array,
	tier: PropTypes.number,
	rank: PropTypes.number
};


export default SilderGroup;
