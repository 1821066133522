import {create} from 'zustand';
import {handleConfiguration} from './helpers';


const initialConfigurationState = {
    name: 'optimized',
    model: '',
    curAccount: null,
    accounts: [],
    value: [],
    models: [],
    tiers: 3,
    ranks: 3,
    custom: {},
    curConfigCache: {},
    optimizationCache: {},
    topHitRates: {}
};

export const useConfigurationStore = create((set, get) => ({
    ...initialConfigurationState,
    updateConfigStore: (action) => set((state) => handleConfiguration(state, action)),
    setValue: (action) => set((state) => {
        const customVal = useCustomSliderGroupStore.getState().value;
        const tierCnt = customVal[0].length;
        const rankCnt = customVal.length;
        return {value: customVal, name: 'custom', tiers: tierCnt, ranks: rankCnt};
    }),
    getModel: () => get().model,
    getValue: () => get().value,
    getCurrentCache: () => get().curConfigCache,
    getConfigName: () => get().name,
    getOptimizations: () => get().optimizationCache,
    reset: () => set(initialConfigurationState)
}));

export const usePageStore = create((set) => ({
    currentPage: 'distribution',
    changePage: (page) => set((state) => ({currentPage: page}))
}));

export const useSessionStore = create((set) => ({
    exp: 0,
    isSuperUser: false,
    setExp: (exp) => set((state) => ({exp})),
    setSuperUser: (bool) => set((state) => ({isSuperUser: bool}))
}));

export const useCustomSliderGroupStore = create((set, get) => ({
    disabled: true,
    value: [],
    setActive: (exp) => set((state) => ({disabled: false})),
    setCustomValue: (exp) => set((state) => ({value: useConfigurationStore.getState().value}))
}));
