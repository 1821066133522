
import React, {useEffect, useState} from 'react';
import './SimulatorPage.css';
import {
    Flex, Heading, Divider, Center, Spinner, Wrap, WrapItem, Box, Text, Icon,
    Tag,
    TagLeftIcon,
    TagLabel,
    HStack
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
    BarChart,
    XAxis,
    YAxis,
    Customized,
    Rectangle,
    PieChart,
    Pie,
    Tooltip, Legend, Bar, ResponsiveContainer, Cell
} from 'recharts';
import NavLink from '../components/NavLink';
import {BsArrowLeftShort, BsCaretUpFill, BsCaretDownFill} from 'react-icons/bs';
import {MdDateRange} from 'react-icons/md';
import {useConfigurationStore} from '../store/store';
import {GoPrimitiveDot} from 'react-icons/go';
import _ from 'lodash';

function floatToPercentage(float) {
    return (parseFloat(float) * 100).toFixed(0) + '%';
}
const renderTool = (props) => {
    const {active, payload, label} = props;
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                <p className='intro'>Conversions:</p>
                <p>Baseline: {payload[0].payload.baseline}</p>
                {/* <p>25th Percentile: {payload[0].payload.lower}</p> */}
                <p>Propair: {payload[0].payload.mean}</p>
                {/* <p>75th Percentile: {payload[0].payload.upper}</p> */}
            </div>
        );
    }
    return null;
};

// using Customized gives you access to all relevant chart props
const CustomMedian = (props) => {
    const {formattedGraphicalItems, xAxisMap} = props;
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const chartW = xAxisMap[0].width;
    const xDomain = xAxisMap[0].niceTicks;
    const maxTick = xDomain.at(-1);
    // render custom content using points from the graph
    return firstSeries?.props?.data.map((firstSeriesPoint, index) => {
        const {mean} = firstSeriesPoint.payload;
        const rWidth = chartW / (maxTick / mean);
        return (
            <Rectangle
                key={firstSeriesPoint.name}
                width={3}
                height={firstSeriesPoint.height}
                x={firstSeriesPoint.x + rWidth}
                y={firstSeriesPoint.y}
                fill='#434346'
            />

        );
    });
};

// using Customized gives you access to all relevant chart props
const CustomBaseline = (props) => {
    const {formattedGraphicalItems, xAxisMap} = props;
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const chartW = xAxisMap[0].width;
    const xDomain = xAxisMap[0].niceTicks;
    const maxTick = xDomain.at(-1);
    // render custom content using points from the graph
    return firstSeries?.props?.data.map((firstSeriesPoint, index) => {
        const {baseline} = firstSeriesPoint.payload;
        const rWidth = chartW / (maxTick / baseline);

        return (
            <Rectangle
                key={firstSeriesPoint.name}
                width={3}
                height={firstSeriesPoint.height}
                x={firstSeriesPoint.x + rWidth}
                y={firstSeriesPoint.y}
                fill='#59A14F'
            />

        );
    });
};

// using Customized gives you access to all relevant chart props
const CustomizedRectangle = (props) => {
    const {formattedGraphicalItems, xAxisMap} = props;
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const chartW = xAxisMap[0].width;
    const xDomain = xAxisMap[0].niceTicks;
    const maxTick = xDomain.at(-1);
    // render custom content using points from the graph
    return firstSeries?.props?.data.map((firstSeriesPoint, index) => {
        const {lower, upper} = firstSeriesPoint.payload;
        const rWidth = chartW / (maxTick / (upper - lower));
        return (
            <Rectangle
                key={firstSeriesPoint.name}
                width={rWidth}
                height={firstSeriesPoint.height}
                x={firstSeriesPoint.x + firstSeriesPoint.width - rWidth}
                y={firstSeriesPoint.y}
                fill='#C0D8F0'
            />

        );
    });
};

const rex = /\d/g;

const tierMap2 = {
    '1': 'A',
    '2': 'B',
    '3': 'C',
    '4': 'D',
    '5': 'E'
};


const ChartPage = ({chartData}) => {
    const [agentTiers, setAgentTiers] = useState(null);
    const [sources, setSources] = useState(null);
    const [conversions, setConversions] = useState(null);
    const distroValue = useConfigurationStore((state) => state.value);
    const topHitRates = useConfigurationStore((state) => state.topHitRates);
    const curModel = useConfigurationStore((state) => state.model);
    const curAccount = useConfigurationStore((state) => state.curAccount);

    const numOfRanks = distroValue.length;
    const numOfTiers = distroValue[0].length;

    const hexValues = {
        green: '#59A14F',
        lime: '#8BD17D',
        cyan: '#489893',
        lightgreen: '#86BBB6',
        lighterblue: '#A0CBE8',
        lightblue: '#5FA2CE',
        blue: '#1170AA'
    };

    const generatePieChartData = (originalData) => {
        const formattedData = {};

        Object.entries(originalData).forEach(([tier, element]) => {
            formattedData[tier] = {};
            for (const [category, ranks] of Object.entries(element)) {
                formattedData[tier][category] = Object.entries(ranks).map(([name, value]) => {
                    return {name, value};
                });
            }
        });
        return formattedData;
    };

    const formatSourcesData = (originalData) => {
        const formattedData = {};

        originalData.forEach((element) => {
            const {name, ...rest} = element;
            formattedData[name] = [];
            for (const [rank, value] of Object.entries(rest)) {
                formattedData[name].push({name: rank, value});
            }
        });
        return formattedData;
    };


    useEffect(() => {
        if (!_.isEmpty(chartData)) {
            if (chartData.agent_tiers) {
                setAgentTiers(generatePieChartData(chartData.agent_tiers));
            }

            if (chartData.key_sources) {
                setSources(formatSourcesData(chartData.key_sources));
            }

            if (chartData.projected_conversions) {
                let conversions = _.pickBy(chartData.projected_conversions, (value, key) => key.includes('tier'));
                conversions = _.mapValues(conversions, (value, key) => {
                    const tier = key.match(rex)[0];
                    const {quantiles, mean, baseline} = value;
                    return {
                        name: `Tier ${tierMap2[tier]}`,
                        tier,
                        upper: parseFloat(quantiles[3]).toFixed(2),
                        lower: parseFloat(quantiles[1]).toFixed(2),
                        baseline: parseFloat(baseline).toFixed(2),
                        mean: parseFloat(mean).toFixed(2)
                    };
                });
                setConversions(Object.values(conversions));
            }
        }
    }, [chartData]);

    if (!agentTiers || !conversions) {
        return (
            <Center pt={5} pb={5}><Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
                label="Loading..."
            /></Center>
        );
    }


    const tierMap = {
        tier_1: 'TIER A',
        tier_2: 'TIER B',
        tier_3: 'TIER C',
        tier_4: 'TIER D',
        tier_5: 'TIER E'
    };
    const pieParams = {
        startAngle: 450,
        endAngle: 90,
        innerRadius: 50,
        outerRadius: 80,
        paddingAngle: 1,
        cx: '50%',
        cy: '50%',
        label: ({cx, cy, percent}) => {
            const v = floatToPercentage(percent);
            return v;
        }
    };
    const re = /\D/g;
    const renderedAgentTierCharts = Object.entries(agentTiers).map(([key, value]) => (
        <WrapItem key={key} flexDir="column" alignItems="center">
            <Heading size='sm' fontWeight="600" color="#4A5568" textAlign="center">AGENT {tierMap[key]}</Heading>
            <Flex>
                <Flex wrap={true} alignItems='center' direction='column'>
                    <PieChart width={275} height={225}>
                        <Pie
                            {...pieParams}
                            data={value.baseline}
                            margin={{right: 15, left: 15}}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {value.baseline.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={Object.values(hexValues)[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                    <Text fontSize='xs' fontWeight='700'>BASELINE</Text>
                </Flex>
                <Flex wrap={true} alignItems='center' direction='column'>
                    <PieChart width={275} height={225}>
                        <Pie
                            {...pieParams}
                            data={value.projected}
                            margin={{right: 15, left: 15}}
                            formatter={(value, entry, index) => <span className='legend-text'>{value}</span>}
                            dataKey="value"
                        >
                            {value.projected.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={Object.values(hexValues)[index]} />
                            ))}

                        </Pie>
                    </PieChart>
                    <Text fontSize='xs' fontWeight='700'>PROJECTED</Text>
                </Flex>
            </Flex>
        </WrapItem>
    ));

    const renderedSourceCharts = Object.entries(sources).map(([source, data]) => (
        <WrapItem key={source} flexDir="column" alignItems="center">
            <Flex wrap={true} alignItems='center' direction='column'>
                <PieChart width={275} height={225}>
                    <Pie
                        {...pieParams}
                        data={data}
                        margin={{right: 15, left: 15}}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={Object.values(hexValues)[index]} />
                        ))}
                    </Pie>
                </PieChart>
                <Text fontSize='xs' fontWeight='700'>{source}</Text>
            </Flex>
        </WrapItem>
    ));


    const renderedTableRows = conversions.map((tierData) => {
        const {tier, mean, baseline} = tierData;
        return (
            <tr key={tier}>
                <td>{tierMap2[tier]}</td>
                <td>{parseFloat(mean - baseline).toFixed(1)}</td>
            </tr>
        );
    });

    const getTotalConversions = () => {
        const deltas = [];

        conversions.forEach((tierData) => {
            const {mean, baseline} = tierData;
            deltas.push(parseFloat(parseFloat(mean - baseline).toFixed(1)));
        });

        // const total = deltas.reduce((sum, x) => sum + x);
        const {projected_mean, baseline} = chartData.projected_conversions.combined_hit_cnt;
        const total = parseInt(projected_mean - baseline);
        const positive = total > 0;
        const percentage = Math.round(((projected_mean / baseline) - 1) * 100);


        return (
            <Box w="550px" className='conversion-summary'>
                <Flex p="0.5rem 1.2rem" justifyContent='space-between' className='c-header'>
                    <span>Total Conversions</span>
                    <span>{parseInt(projected_mean)}</span>
                </Flex>
                <Box className='c-inner-content'>
                    <Flex p="1rem  1.2rem" justifyContent='space-between'>
                        <span>BEST POSSIBLE OUTCOME</span>
                        <span>{parseFloat(topHitRates[curAccount][curModel]).toFixed(1)}</span>
                    </Flex>
                    <Flex p="1rem  1.2rem" justifyContent='space-between'>
                        <span>BASELINE</span>
                        <span>{parseInt(baseline)}</span>
                    </Flex>
                    <Flex p="1rem 1.2rem" justifyContent='space-between' alignItems='center' >
                        <span>PROJECTED<br /> VARIANCE</span>
                        <Text color={positive ? 'green.600' : 'red.600'} className='c-increment'>
                            <Icon verticalAlign={positive ? 'top' : 'middle'} as={positive ? BsCaretUpFill : BsCaretDownFill}></Icon>
                            <span>{positive && '+'}{total}</span>
                            <span className='divide'>|</span>
                            <span>{percentage}%</span>
                        </Text>
                    </Flex>
                </Box>
            </Box>
        );
    };

    const renderedRankLegend = [...Array(numOfRanks).keys()].map((rankNum, index) => {
        const rankVal = index + 1;
        return (
            <Text key={rankVal} fontSize="sm">
                <Icon boxSize={10} as={GoPrimitiveDot} color={Object.values(hexValues)[index]} />
                <span>RANK {rankVal}</span>
            </Text>
        );
    });

    const maxDomain = chartData.projected_conversions.max_hit_cnt;

    return (
        <div>

            <Flex p="2rem 4rem" flexDir="row" justifyContent="space-between">
                <Box flexDir="column" className='page-title'>
                    <Heading size='xs' color='orange.500'>SIMULATOR</Heading>
                    <Heading>Results</Heading>
                </Box>
                <NavLink to='distribution' colorScheme="blue" icon={BsArrowLeftShort}>Back to Configuration</NavLink>
            </Flex>
            <Flex wrap="wrap" direction="column">
                <Flex p="2rem 4rem" flexDir="row" alignItems="center" justifyContent="space-between">
                    <Heading size="lg" >Projected Conversions (Monthly)</Heading>
                    <Tag size='lg' variant='outline' colorScheme='linkedin'>
                        <TagLeftIcon boxSize='18px' as={MdDateRange} />
                        <TagLabel>Data trained from: {chartData['dataset_dates'].min} to {chartData['dataset_dates'].max}</TagLabel>
                    </Tag>
                </Flex>
                <Flex width='100%' pl='4rem' pr='3rem'>
                    <table className='conversions-table'>
                        <thead>
                            <tr>
                                <th>TIERS</th>
                                <th>AVERAGE INCREMENTS</th>
                            </tr>

                        </thead>
                        <tbody>
                            {renderedTableRows}
                        </tbody>
                    </table>
                    <ResponsiveContainer minWidth={0} height={200 + (30 * conversions.length)}>
                        <BarChart
                            layout="vertical"
                            data={conversions}
                            margin={{
                                top: 15 + (5 * conversions.length)
                            }}

                        >
                            <XAxis domain={[0.0, maxDomain + 1]} type="number" allowDecimals={true} tickCount={10} tickFormatter={(value) => parseFloat(value).toFixed(1)} />
                            <YAxis axisLine={false} tick={false} dataKey="name" type="category" />
                            <Tooltip cursor={{fill: 'rgba(241,105,54,0.80)'}} content={renderTool} wrapperStyle={{outline: 'none'}} />
                            <Legend iconType='line' verticalAlign="bottom" height={15}
                                formatter={(value, entry, index) => <span className='legend-text'>{value}</span>}
                                payload={[
                                    {value: `Baseline`, type: 'line', color: '#59A14F', id: 'ID02'},
                                    {value: `Median`, type: 'line', color: '#434346', id: 'ID01'},
                                    {value: `25th-75th percentile`, type: 'rect', color: '#C0D8F0', id: 'ID03'}
                                ]} />
                            <Bar dataKey="upper" fill="#eee" background={{fill: '#eee'}} barSize={40} />
                            <Customized component={CustomizedRectangle} />
                            <Customized component={CustomMedian} />
                            <Customized component={CustomBaseline} />
                        </BarChart>
                    </ResponsiveContainer>
                    {getTotalConversions()}
                </Flex>
                <Divider mb="2rem" mt="2rem" />
                <Flex p="2rem 2rem" flexDir="row" justifyContent="space-between">
                    <Heading size="lg">RANK Distribution: Agent Tiers</Heading>
                    <Flex alignItems="center" className='rank-legend'>
                        <Text fontSize="sm">LEGEND:</Text>
                        {renderedRankLegend}
                    </Flex>
                </Flex>
                <Wrap justify="center" width='100%' className='pie-charts' spacing={0}>
                    {renderedAgentTierCharts}
                </Wrap>
                <Flex p="2rem 2rem" flexDir="row" justifyContent="space-between">
                    <Heading size="lg">RANK Distribution: Key Sources</Heading>
                    <Flex alignItems="center" className='rank-legend'>
                        <Text fontSize="sm">LEGEND:</Text>
                        {renderedRankLegend}
                    </Flex>
                </Flex>
                <Wrap mt="2rem" mb="2rem" justify="space-evenly" width='100%' spacing={10}>
                    {renderedSourceCharts}
                </Wrap>
            </Flex>
        </div>
    );
};

ChartPage.propTypes = {
    chartData: PropTypes.object
};

export default ChartPage;
