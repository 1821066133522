import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const simulateRequest = async (params, headers) => {
       const {data} = await axios.post(` ${BASE_URL}/simulate`, {...params}, {headers});
       return data;
};


export const fetchOptimizations = async (headers) => {
       const response = await axios.get(` ${BASE_URL}/distro-configs`, {headers});
       return response.data;
};

