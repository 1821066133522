
import '../App.css';
import React from 'react';


import {Container, Flex, Box, Image, Center, Text} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';

import bgImg from '../img/Background@2x.jpg';

function PublicLayout() {
	return (

		<Flex className='public-bg' direction='row' h="100vh">
			<Image src={bgImg} fit='cover' htmlWidth="50%" htmlHeight="100%" />
			<Flex w="50%" direction='column' overflow="scroll">
				<Center flexGrow="1" pb="2rem">
					<Flex direction='column'>
						<Image marginBottom="3rem" htmlWidth="180px" src="https://www.propair.ai/wp-content/uploads/2021/03/logo.png" />
						<Box mb="2rem" w="60px" border="2px solid" borderRadius="4px 2px 8px 7px" borderColor="orange.500"/>
						<Outlet />
					</Flex>
				</Center>
				<Text fontSize="xs" w="100%" textAlign='center' p='1rem' borderTop="1px solid" borderColor='gray.200'>
					Copyright © 2023 ProPair. All rights reserved.
				</Text>
			</Flex>
		</Flex>

	);
}

export default PublicLayout;
