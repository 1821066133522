import _ from 'lodash';
import {
    ADD_PRE_CONFIGS,
    CHANGE_CONFIG,
    CHANGE_MODEL,
    CHANGE_ACCOUNT,
    CHANGE_RANKS,
    CHANGE_TIERS, CUSTOMIZED, OPTIMIZED, RANDOM,
    SET_CUSTOM_CONFIG, CURRENT
} from './constants';

const operators = {
    '+': function(a, b) {
        return a + b;
    },
    '-': function(a, b) {
        return a - b;
    },
    '/': function(a, b) {
        return a / b;
    },
    '*': function(a, b) {
        return a * b;
    }
};

function applyToArray(array, operator, value) {
    const newArray = Array(array.length);
    for (let i = 0, length = array.length; i < length; i++) {
        newArray[i] = operators[operator](array[i], value);
    }
    return newArray;
}
function generateRandArr(count) {
    const low = 0.1;
    const cuts = _.fill(Array(count)).map((x) => Math.random());
    const out = applyToArray(applyToArray(cuts, '/', _.sum(cuts)), '*', (1 - low * count));
    const weights = applyToArray(out, '+', low);
    return weights;
}

function generateRandDist(tierCnt, binCnt) {
    return _.range(binCnt).map((x) => generateRandArr(tierCnt));
}

const minDist = 3;
const maxDist = 5;

export const addToDistribution = (currentDist, type, updateFn) => {
    if (currentDist === maxDist) return;
    updateFn({
        type: type === 'tiers' ? CHANGE_TIERS : CHANGE_RANKS,
        payload: 1
    });
};

export const subtractFromDistribution = (currentDist, type, updateFn) => {
    if (currentDist === minDist) return;
    updateFn({
        type: type === 'tiers' ? CHANGE_TIERS : CHANGE_RANKS,
        payload: -1
    });
};

export const updateConfig = (configName, updateFn) => {
    const configMap = {
        current: CURRENT,
        random: RANDOM,
        custom: CUSTOMIZED,
        optimized: OPTIMIZED
    };
    if (!configMap[configName]) throw Error(`Unrecognized config: ${configName}`);
    updateFn({
        type: CHANGE_CONFIG,
        payload: configMap[configName]
    });
};

export const changeModel = (models, model, updateFn) => {
    if (!models.includes(model)) throw Error(`Unrecognized model: ${model}`);
    updateFn({
        type: CHANGE_MODEL,
        payload: model
    });
};

export function setCustomConfig(rank, index, value, updateFn, useConstraints=true) {
    value = parseFloat(value);
    if (!value) value = 0;
    if (value !== 0) value = value / 100;
    if (value > 1) value = 1;
    updateFn({
        type: SET_CUSTOM_CONFIG,
        payload: {rank, index, value, useConstraints}
    });
}

export const handleConfiguration = (state, action) => {
    function updateValue(name, account, model, tiers, ranks, optimizationCache) {
        switch (name) {
            case OPTIMIZED:
                return optimizationCache[account].models[model][`${tiers}_tiers`][`${ranks}_bins`];
            case CUSTOMIZED:
                return state.custom[tiers][ranks];
            case RANDOM:
                return generateRandDist(tiers, ranks);
            case CURRENT:
                return state.curConfigCache[account][model];
            default:
                return state.value;
        }
    }

    switch (action.type) {
        case ADD_PRE_CONFIGS:
            if (state.value.length) {
                return state;
            } else {
                const {optimal, current} = action.payload;

                const accounts = Object.keys(optimal);
                const curAccount = accounts[0];
                const models = Object.keys(optimal[curAccount].models);

                const topHitRates = {};
                for (const a of accounts) {
                    if (!optimal[a].top_hit_rt) {
                        topHitRates[a] = {};
                        for (const model of models) {
                            topHitRates[model] = 0;
                        }
                    } else {
                        topHitRates[a] = optimal[a].top_hit_rt;
                    }
                }


                const curModel = models[0];
                const optimizationCache = optimal;
                return {
                    ...state,
                    accounts,
                    curAccount,
                    models,
                    topHitRates,
                    curConfigCache: current,
                    optimizationCache,
                    model: curModel,
                    value: updateValue(state.name, curAccount, curModel, state.tiers, state.ranks, optimizationCache)
                };
            }
        case SET_CUSTOM_CONFIG:
            const r = action.payload.rank;
            const {index, value, useConstraints} = action.payload;
            const newV = structuredClone(state.value);

            if (useConstraints) {
                const maxValue = 1;
                const remaining = maxValue - parseFloat(value, 10);
                const oldRemaining = maxValue - newV[r][index];
                const total = _.sum(newV[r]);
                const delta = total - 1;
                newV[r] = newV[r].map((v, i) => {
                    if (i === index) return parseFloat(value, 10);
                    const overflow = delta * (v / (total-newV[r][index]));
                    if (oldRemaining) return (remaining * (v-overflow)) / oldRemaining;
                    return remaining / (state.tiers - 1);
                });
            } else {
                newV[r][index] = value;
            }

            const newCustom = {...state.custom};
            if (!newCustom[state.tiers]) newCustom[state.tiers] = {};
            newCustom[state.tiers][state.ranks] = newV;

            return {
                ...state,
                custom: newCustom,
                name: 'custom',
                value: newV
            };
        case CHANGE_MODEL:
            return {
                ...state,
                model: action.payload,
                value: updateValue(state.name, state.curAccount, action.payload, state.tiers, state.ranks, state.optimizationCache)
            };
        case CHANGE_ACCOUNT:
            const newAccount = action.payload;
            const models = Object.keys(state.optimizationCache[newAccount].models);
            const model = models[0];

            let configName = state.name;
            if (state.name == CURRENT) {
                const testConfig = state.curConfigCache?.[newAccount]?.model;
                if (!testConfig) configName = 'optimized';
            }
            return {
                ...state,
                name: configName,
                models,
                model,
                curAccount: newAccount,
                value: updateValue(configName, newAccount, model, state.tiers, state.ranks, state.optimizationCache)
            };
        case CHANGE_CONFIG:
            return {
                ...state,
                name: action.payload,
                value: updateValue(action.payload, state.curAccount, state.model, state.tiers, state.ranks, state.optimizationCache)
            };

        case CHANGE_TIERS:
            const aT = state.tiers + action.payload;
            return {
                ...state,
                tiers: aT,
                value: updateValue(state.name, state.curAccount, state.model, aT, state.ranks, state.optimizationCache)
            };
        case CHANGE_RANKS:
            const aB = state.ranks + action.payload;
            return {
                ...state,
                ranks: aB,
                value: updateValue(state.name, state.curAccount, state.model, state.tiers, aB, state.optimizationCache)
            };
        default:
            return state;
    }
};
