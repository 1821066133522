
import './App.css';
import React, {useEffect, useContext, useState} from 'react';

import SimulatorPage from './pages/SimulatorPage';


import {Routes, Route} from 'react-router-dom';
import ChangePasswordPage from './pages/ChangePasswordPage';
import Login2Page from './pages/Login2Page';
import MainLayout from './layouts/main';

import PublicLayout
	from './layouts/public';
import RequireAuth from './components/RequireAuth';
import {AccountContext} from './context/account';

function App() {
	const {isAuthenticating} = useContext(AccountContext);
	return (
		!isAuthenticating &&
		<Routes>
			<Route element={<PublicLayout />}>
				<Route path="login" element={<Login2Page />} />
				<Route path="change_password" element={<ChangePasswordPage />} />
			</Route>
			<Route element={<MainLayout />}>
				{/* <Route path="/" element={
					<RequireAuth ><Dashboard /></RequireAuth>} /> */}
				<Route path="/" element={
					<RequireAuth><SimulatorPage /></RequireAuth>
				} />
			</Route>
		</Routes>

	);
}

export default App;
